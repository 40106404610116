<template>
  <div class="account_content">
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div style="display: flex; align-items: center">
        <div style="width: 50px; height: 50px; border-radius: 30px; overflow: hidden">
          <img
            v-if="mediaDetail.authorDetailInfoDTO && mediaDetail.authorDetailInfoDTO.avatar"
            :src="mediaDetail.authorDetailInfoDTO.avatar ? mediaDetail.authorDetailInfoDTO.avatar : ''"
            alt="头像"
            referrerpolicy="no-referrer"
          />
        </div>
        <div style="display: flex; flex-direction: column">
          <div style="margin-left: 5px">{{ mediaDetail.authorDetailInfoDTO.nickname || '-' }}</div>
          <div style="display: flex; align-items: center; height: 45px" v-if="showMore">
            <div
              style="display: flex; align-items: center"
              :title="
                mediaDetail.authorDetailInfoDTO && mediaDetail.authorDetailInfoDTO.authorCode
                  ? mediaDetail.authorDetailInfoDTO.authorCode
                  : ''
              "
            >
              <span
                style="
                  display: inline-block;
                  width: 25px;
                  height: 25px;
                  border-radius: 15px;
                  overflow: hidden;
                  margin: 0 5px;
                "
              >
                <img src="@/assets/icon/xhs_logo.png" alt="" />
              </span>
              {{ mediaDetail.authorDetailInfoDTO.authorCode || '-' }}
            </div>
            <div style="display: flex; align-items: center">
              <span
                style="
                  display: inline-block;
                  width: 25px;
                  height: 25px;
                  border-radius: 3px;
                  overflow: hidden;
                  margin: 0 5px;
                "
              >
                <img
                  v-show="mediaDetail.authorDetailInfoDTO.photoUrl"
                  :src="mediaDetail.authorDetailInfoDTO.photoUrl"
                  alt="头像"
                  referrerpolicy="no-referrer"
                />
              </span>
              {{
                mediaDetail.authorDetailInfoDTO.personalityType
                  ? personalityTypeObject[mediaDetail.authorDetailInfoDTO.personalityType]
                  : ''
              }}
            </div>
          </div>
        </div>
      </div>
      <template v-if="!showMore">
        <a-tooltip>
          <template slot="title">
            <div>授权时间：{{ mediaDetail.authorDetailInfoDTO.p1OauthTime || '-' }}</div>
          </template>
          <div class="sync-succ" v-show="mediaDetail.authorDetailInfoDTO.p1OauthStatus === 1">
            笔记数据
            <a-icon style="margin-left: 5px" type="check-circle" />
          </div>
        </a-tooltip>
        <a-tooltip>
          <template slot="title">
            <div>失效时间：{{ mediaDetail.authorDetailInfoDTO.p1OauthExpireTime || '-' }}</div>
          </template>
          <div class="sync-err" v-show="mediaDetail.authorDetailInfoDTO.p1OauthStatus !== 1">
            笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
          </div>
        </a-tooltip>
      </template>
    </div>
    <template v-if="showMore">
      <div>
        <a-tooltip>
          <template slot="title">
            <div>授权时间：{{ mediaDetail.authorDetailInfoDTO.p1OauthTime || '-' }}</div>
          </template>
          <div class="sync-succ" v-show="mediaDetail.authorDetailInfoDTO.p1OauthStatus === 1">
            笔记数据
            <a-icon style="margin-left: 5px" type="check-circle" />
          </div>
        </a-tooltip>
        <a-tooltip>
          <template slot="title">
            <div>失效时间：{{ mediaDetail.authorDetailInfoDTO.p1OauthExpireTime || '-' }}</div>
          </template>
          <div class="sync-err" v-show="mediaDetail.authorDetailInfoDTO.p1OauthStatus !== 1">
            笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
          </div>
        </a-tooltip>
      </div>
      <div>所属成员: {{ mediaDetail.matrixMemberName || '-' }}</div>
      <div>编码：{{ mediaDetail.matrixMemberCode || '-' }}</div>
    </template>
  </div>
</template>

<script>
import { personalityTypeObject } from '@/pages/app/xhsAgency/xhsMediaList/data.js';

export default {
  props: {
    mediaDetail: {
      default: () => ({
        authorDetailInfoDTO: {},
      }),
    },
    showMore: {
      default: true,
    },
  },
  data() {
    return {
      personalityTypeObject,
    };
  },
};
</script>

<style lang="less" scoped>
.account_content {
  margin: 5px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.sync-err {
  width: 100px;
  height: 25px;
  line-height: 25px;
  padding-left: 15px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  width: 100px;
  height: 25px;
  line-height: 25px;
  padding-left: 15px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}
</style>
