<template>
  <div class="AutoProduction">
    <a-tabs default-active-key="autoConfig" v-model="searchParams.searchType" @change="tabClickCallback">
      <a-tab-pane key="autoConfig" tab="自动化配置">
        <div class="tips-txt">每个选题的自动化生产配置，仅针对最小一级的选题；</div>
        <div class="tips-txt">
          自动化生产时材料选取优先级：未暂停生产过的运营爆文＞暂停生产过的运营爆文＝文案模板＞平台爆文
        </div>
        <div style="margin: 10px 0">
          <a-select
            style="width: 200px"
            allowClear
            v-model="searchParams.status"
            placeholder="状态"
            @change="getDataList"
          >
            <a-select-option key="1" value="OPEN">启用</a-select-option>
            <a-select-option key="0" value="STOP">停用</a-select-option>
          </a-select>
        </div>
      </a-tab-pane>
      <a-tab-pane key="brandConfig" tab="起号品牌配置">
        <div class="tips-txt">
          试运营、起号阶段，指定媒体号采用哪个品牌的材料进行自动化生产，未配置则采用矩阵管理的品牌关系。
        </div>
        <div style="margin: 10px 15px 10px 0; display: flex; justify-content: space-between">
          <div>
            <a-select
              style="width: 200px"
              allowClear
              v-model="searchParams.authorId"
              placeholder="媒体号"
              @change="getDataList()"
            >
              <a-select-option v-for="val in mediaList" :key="val.authorId" :value="val.authorId">{{
                val.nickname
              }}</a-select-option>
            </a-select>
            <BrandSelectInput
              style="width: 200px"
              placeholder="品牌"
              v-model="searchParams.principalIdListData"
              show-search
              option-filter-prop="children"
              allowClear
              @onChange="getDataList()"
            />
          </div>
          <a-button
            type="primary"
            @click="
              () => {
                showModal = true;
                handleTrack('add_init_media');
              }
            "
          >
            添加
          </a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="accountCheck" tab="文案生产数量检测">
        <div class="tips-txt">每个选题当前可用于生产的材料数量</div>
        <div style="margin: 10px 0">
          <a-radio-group v-model="searchParams.accountType" @change="handleTypeChange">
            <a-radio-button value="PRINCIPAL"> 品牌 </a-radio-button>
            <a-radio-button value="AUTHOR"> 媒体号 </a-radio-button>
          </a-radio-group>
          <template v-if="searchParams.accountType === 'PRINCIPAL'">
            <BrandSelectInput
              style="margin: 0 5px; width: 200px"
              placeholder="品牌"
              v-model="searchParams.principalIdListData"
              show-search
              option-filter-prop="children"
              allowClear
              @change="getDataList()"
            />
          </template>
          <template v-else>
            <a-select
              style="width: 200px; margin: 0 5px"
              allowClear
              v-model="searchParams.meidaId"
              placeholder="媒体号"
              @change="getDataList()"
            >
              <a-select-option v-for="val in mediaList" :key="val.authorId" :value="val.authorId">{{
                val.nickname
              }}</a-select-option>
            </a-select>
          </template>
          <a-button type="primary" @click="reset">重置</a-button>
        </div>
      </a-tab-pane>
    </a-tabs>

    <div style="width: 100%; overflow: auto">
      <a-table
        style="margin-top: 10px; width: 100%"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.id"
        :pagination="false"
        @change="onTableChange"
      >
        <div slot="writtingTheme" slot-scope="text, record">
          <div class="themeList">
            <div class="themeItem" :style="`background-color: ${record.colorHex || '#adafb3'}`">
              {{ record.tagName }}
            </div>
          </div>
        </div>
        <div slot="picSwitchHeader">
          图片生产
          <a-tooltip placement="top" title="图片预生产暂仅支持生产本品图片"
            ><a-icon type="exclamation-circle"
          /></a-tooltip>
        </div>
        <div slot="picSwitch" slot-scope="text, record">
          <a-switch
            :checked="record.productionImgStatus === 'OPEN'"
            :loading="switchLoading"
            @change="handleImgSwitch(record)"
          />
        </div>
        <div slot="productSwitch" slot-scope="text, record">
          <a-switch
            :checked="record.productionStatus === 'OPEN'"
            :loading="switchLoading"
            @change="handleSwitch(record)"
          />
        </div>
        <div class="media-column" slot="mediaAccount" slot-scope="text, record">
          <template v-if="record.authorDetailInfoDTO && record.authorDetailInfoDTO.authorCode">
            <div class="media-left">
              <img :src="record.authorDetailInfoDTO.avatar" alt="头像" referrerpolicy="no-referrer" />
            </div>
            <div class="media-right">
              <div style="margin-left: 15px" class="blue-text" @click="handleJumpXHS(record)">
                {{ record.authorDetailInfoDTO.nickname }}
              </div>
              <div class="line-flex right-txt">
                <div class="logo" v-show="record.authorDetailInfoDTO.authorCode">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div>{{ record.authorDetailInfoDTO.authorCode || '-' }}</div>
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      display: inline-block;
                      width: 25px;
                      height: 25px;
                      border-radius: 3px;
                      overflow: hidden;
                      margin: 0 5px;
                    "
                  >
                    <img
                      style="width: 100%; height: 100%"
                      v-show="record.authorDetailInfoDTO.photoUrl"
                      :src="record.authorDetailInfoDTO.photoUrl"
                      alt="头像"
                      referrerpolicy="no-referrer"
                    />
                  </span>
                  {{
                    record.authorDetailInfoDTO.personalityType
                      ? personalityTypeObject[record.authorDetailInfoDTO.personalityType]
                      : ''
                  }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div slot="operation" slot-scope="text, record">
          <div v-show="searchParams.searchType == 'autoConfig'">
            <a-button type="link" @click="openDraw(record)">编辑文案生产配置</a-button>
          </div>
          <div v-show="searchParams.searchType == 'brandConfig'">
            <a-popconfirm title="是否删除？" ok-text="确定" cancel-text="取消" @confirm="handleDel(record)">
              <a-button type="link">删除</a-button>
            </a-popconfirm>
          </div>
        </div>
      </a-table>
    </div>
    <base-pagination
      v-show="searchParams.searchType === 'brandConfig'"
      :currentPage="pagination.current"
      :options="['10', '30', '50']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <!-- 配置抽屉 -->
    <a-drawer
      title="文案生产配置"
      :width="540"
      :visible="showDraw"
      :body-style="{ paddingBottom: '80px' }"
      @close="handleCloseDrawer"
    >
      <a-form-model
        style="width: 100%"
        :model="drawerForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :rules="rules"
        ref="drawerForm"
      >
        <div style="font-weight: bolder; font-size: 18px; margin-bottom: 15px">选题: {{ currentTagName }}</div>
        <a-form-model-item label="生产方式" prop="autoType">
          <a-select placeholder="请选择生产方式" v-model="drawerForm.autoType">
            <a-select-option value="AI">匹配材料后AI改写</a-select-option>
            <a-select-option value="MATCH">仅匹配</a-select-option>
            <a-select-option value="INTERESTED">AI作文</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="AI改写类型" prop="rewriteType" v-if="drawerForm.autoType === 'AI'">
          <a-select option-filter-prop="children" placeholder="改写类型" v-model="drawerForm.rewriteType">
            <a-select-option v-for="types in rewriteTypeList" :value="types.aiRewriteType" :key="types.aiRewriteType">
              {{ types.aiRewriteName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <template v-if="drawerForm.autoType !== 'INTERESTED'">
          <a-form-model-item prop="rewriteCount">
            <span slot="label">
              生产阈值&nbsp;
              <a-tooltip title="一份材料每生产 x 次即进入暂停生产状态，期间不可用于生产">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-input suffix="次" type="number" :min="0" v-model="drawerForm.rewriteCount" />
          </a-form-model-item>
          <a-form-model-item prop="stopDay">
            <span slot="label">
              暂停时长&nbsp;
              <a-tooltip title="暂停生产的天数，期间不可用于生产">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-input suffix="天" type="number" :min="0" v-model="drawerForm.stopDay" />
          </a-form-model-item>
          <a-form-model-item prop="repeatStopDay">
            <span slot="label">
              媒体号间隔&nbsp;
              <a-tooltip title="同一个媒体号x天内不取同一份材料进行生产">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-input suffix="天" type="number" :min="0" v-model="drawerForm.repeatStopDay" />
          </a-form-model-item>
          <a-form-model-item prop="bland">
            <span slot="label">
              限定品牌&nbsp;
              <a-tooltip title="开启后仅选取选题内对应品牌的文案材料进行自动化生产">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-switch v-model="drawerForm.limitedBrand" />
          </a-form-model-item>
        </template>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 8px" @click="handleCloseDrawer"> 取消 </a-button>
        <a-button type="primary" :loading="saveLoading" @click="handleDrawerSave"> 保存 </a-button>
      </div>
    </a-drawer>

    <!-- 添加弹窗 -->
    <a-modal
      title="添加媒体号"
      :visible="showModal"
      :confirm-loading="confirmLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-select
        style="width: 100%"
        v-model="addForm.publishAccount"
        allowClear
        @change="handleMediaChange"
        placeholder="请选择账号"
      >
        <a-select-option v-for="val in mediaList" :key="val.code" :value="`${val.code},${val.authorId}`">{{
          val.nickname
        }}</a-select-option>
      </a-select>
      <a-icon type="loading" v-show="mediaSearchLoading" />
      <AccountDetailComp
        v-if="mediaDetail.authorId || (mediaDetail.authorDetailInfoDTO && mediaDetail.authorDetailInfoDTO.authorId)"
        :mediaDetail="mediaDetail"
      />

      <div style="margin: 20px 0 5px">生产材料来自：</div>
      <BrandSelectInput
        style="width: 100%"
        placeholder="选择品牌"
        v-model="addForm.principalIdListData"
        show-search
        option-filter-prop="children"
        allowClear
      />
    </a-modal>
  </div>
</template>

<script>
import { autoColumns, brandColumns, accountColumns } from './constant';
import { personalityTypeObject } from '@/pages/app/xhsAgency/xhsMediaList/data.js';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import AccountDetailComp from '@/pages/app/xhsAgency/aigc/components/AccountDetailComp.vue';
import { trackRequest } from '@/utils/track';

import api from '@/api/xhsAgencyApi';

export default {
  name: 'AutoProduction',
  components: { TopicTreeInput, BrandSelectInput, AccountDetailComp },
  data() {
    const checkInteger = (rule, value, callback) => {
      const isInterger = new RegExp(/^-?[0-9]\d*$/).test(value);
      if (!isInterger || value < 0) {
        return callback(new Error('数值需为整数'));
      } else {
        return callback();
      }
    };
    const rules = {
      autoType: [{ required: true, message: '请选择类型方式', trigger: 'change' }],
      rewriteCount: [
        { required: true, message: '请输入次数', trigger: 'blur' },
        { validator: checkInteger, trigger: 'blur' },
      ],
      rewriteType: [{ required: true, message: '请选择类型', trigger: 'change' }],
      repeatStopDay: [
        { required: true, message: '请输入天数', trigger: 'blur' },
        { validator: checkInteger, trigger: 'blur' },
      ],
      stopDay: [
        { required: true, message: '请输入天数', trigger: 'blur' },
        { validator: checkInteger, trigger: 'blur' },
      ],
    };
    return {
      rules,
      personalityTypeObject,
      autoColumns,
      brandColumns,
      accountColumns,
      searchParams: {
        searchType: 'autoConfig',
        status: undefined,
        accountType: 'PRINCIPAL',
        sortFieldList: [],
        principalIdListData: undefined,
        meidaId: undefined,
      },
      mediaList: [],
      tableLoading: false,
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      showDraw: false,
      drawerForm: {
        autoType: undefined,
        createTimes: undefined,
        rewriteType: undefined,
        stopDay: undefined,
        rewriteCount: undefined,
        limitedBrand: false,
      },
      rewriteTypeList: [],
      showModal: false,
      confirmLoading: false,
      addForm: {
        publishAccount: undefined,
        principalIdListData: undefined,
      },
      mediaSearchLoading: false,
      mediaDetail: {},
      saveLoading: false,
      switchLoading: false,
      currentTagName: undefined,
    };
  },
  created() {
    this.init();
  },
  computed: {
    columns() {
      let columns = [];
      switch (this.searchParams.searchType) {
        case 'autoConfig': // 自动化配置
          columns = autoColumns;
          break;
        case 'brandConfig': // 起号品牌
          columns = brandColumns;
          break;
        case 'accountCheck': // 数量检测
          columns = accountColumns;
          break;
        default:
          break;
      }
      return columns;
    },
  },
  methods: {
    async init() {
      this.getMediaList();
      this.getRewriteTypeList();
      this.getDataList();
    },
    tabClickCallback(key) {
      this.searchParams = this.$options.data().searchParams;
      this.searchParams.searchType = key;
      this.pagination.current = 1;
      this.getDataList();
      const tabList = { autoConfig: 'marketing', brandConfig: 'practical_info', accountCheck: 'customize' };
      this.handleTrack('toggle_tab', {
        tab: tabList[key],
      });
    },
    async getDataList() {
      if (this.tableLoading) return;
      // this.searchParams.searchType: autoConfig-自动配置 brandConfig-品牌配置 accountCheck-数量检测
      let API_NAME = '';
      let PARAMS = '';
      switch (this.searchParams.searchType) {
        case 'autoConfig':
          API_NAME = 'getAutoProductList';
          const status = this.searchParams.status;
          PARAMS = { status };
          break;
        case 'brandConfig':
          API_NAME = 'getBrandConfigList';
          const sourcePrincipalId = this.searchParams.principalIdListData
            ? this.searchParams.principalIdListData.split(',')[0]
            : undefined;
          const sourcePrincipalName = this.searchParams.principalIdListData
            ? this.searchParams.principalIdListData.split(',')[1]
            : undefined;
          PARAMS = {
            page: this.pagination.current,
            size: this.pagination.pageSize,
            authorId: this.searchParams.authorId,
            sourcePrincipalId,
            sourcePrincipalName,
          };
          break;
        case 'accountCheck':
          API_NAME = 'getProduceCheckList';
          const principalId = this.searchParams.principalIdListData
            ? this.searchParams.principalIdListData.split(',')[0]
            : undefined;
          const id = this.searchParams.accountType === 'PRINCIPAL' ? principalId : this.searchParams.meidaId;
          PARAMS = {
            id,
            type: this.searchParams.accountType,
          };
          break;
        default:
          break;
      }

      this.tableLoading = true;
      await this.handleRequest(
        API_NAME,
        (data) => {
          if (this.searchParams.searchType === 'brandConfig') {
            this.dataList = data.list;
            this.pagination.total = data.total;
            return;
          }
          this.dataList = data;
        },
        PARAMS
      ).finally(() => (this.tableLoading = false));
    },
    async getMediaList() {
      await this.handleRequest(
        'getAgencyList',
        (data) => {
          this.mediaList = data.list?.filter((v) => v.authorId) || [];
        },
        {
          page: 1,
          size: 200,
          status: 'NORMAL',
        }
      );
    },
    async handleImgSwitch(record) {
      if (record.productionImgStatus === 'STOP' && !record.xhsProductionConfigDTO?.autoType)
        return this.$message.info('请先编辑生产配置');
      const id = record.id;
      const status = record.productionImgStatus === 'OPEN' ? 'STOP' : 'OPEN';
      this.switchLoading = true;
      const { code, message } = await api.switchImgAutoStatus(id, status).finally(() => (this.switchLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    async handleSwitch(record) {
      this.handleTrack('switch_auto_procuess_text');
      if (record.productionStatus === 'STOP' && !record.xhsProductionConfigDTO?.autoType)
        return this.$message.info('请先编辑生产配置');
      const id = record.id;
      const status = record.productionStatus === 'OPEN' ? 'STOP' : 'OPEN';
      this.switchLoading = true;
      const { code, message } = await api.switchAutoStatus(id, status).finally(() => (this.switchLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    async handleDel({ id }) {
      this.handleTrack('delete_init_media');

      const { code, message } = await api.delBrandConfig(id);
      if (code === 200) {
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current--;
        }
        this.$message.success('删除成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    openDraw(record) {
      this.handleTrack('edit_auto_procuess_text');

      if (record.xhsProductionConfigDTO?.autoType) {
        const { autoType, limitedBrand, repeatStopDay, rewriteCount, rewriteType, stopDay } =
          record.xhsProductionConfigDTO;
        this.drawerForm = {
          autoType,
          limitedBrand: limitedBrand ? true : false,
          repeatStopDay,
          rewriteCount,
          rewriteType,
          stopDay,
        };
      }
      this.drawerForm.id = record.id;
      this.currentTagName = record.tagName;
      this.showDraw = true;
    },
    handleDrawerSave() {
      this.$refs.drawerForm.validate(async (valid) => {
        if (valid) {
          const xhsProductionConfigDTO = JSON.parse(JSON.stringify(this.drawerForm));
          delete xhsProductionConfigDTO.id;
          const params = {
            id: this.drawerForm.id,
            xhsProductionConfigDTO,
          };
          console.log(params);
          this.saveLoading = true;
          const { code, message } = await api.updateAutoConfig(params).finally(() => (this.saveLoading = false));
          if (code === 200) {
            this.handleCloseDrawer();
            this.$message.success('保存成功');
            this.getDataList();
          } else {
            this.$message.error(message);
          }
        }
      });
    },
    handleCloseDrawer() {
      this.$refs.drawerForm.clearValidate();
      this.drawerForm = this.$options.data().drawerForm;
      this.currentTagName = undefined;
      this.showDraw = false;
    },
    reset() {
      this.searchParams.accountType = 'PRINCIPAL';
      this.searchParams.meidaId = undefined;
      this.searchParams.principalIdListData = undefined;
      this.getDataList();
    },
    handleMediaChange(val) {
      this.mediaDetail = {};
      // 媒体号信息回显
      val && this.getMediaData(val, true);
    },
    async getMediaData(authorId) {
      this.mediaSearchLoading = true;
      await this.handleRequest(
        'getMediaDetailById',
        (data) => {
          this.mediaDetail = data;
        },
        { authorId }
      ).finally(() => (this.mediaSearchLoading = false));
    },
    async handleModalOk() {
      if (!this.addForm.publishAccount) return this.$message.info('请选择账号');
      if (!this.addForm.principalIdListData) return this.$message.info('请选择品牌');
      const params = {
        authorCode: this.addForm.publishAccount.split(',')[0],
        authorId: this.addForm.publishAccount.split(',')[1],
        sourcePrincipalId: this.addForm.principalIdListData.split(',')[0],
        sourcePrincipalName: this.addForm.principalIdListData.split(',')[1],
      };
      this.confirmLoading = true;
      await this.handleRequest(
        'addBrandConfig',
        () => {
          this.handleModalCancel();
          this.$message.success('添加成功');
          this.getDataList();
        },
        params
      ).finally(() => (this.confirmLoading = false));
    },
    handleModalCancel() {
      this.addForm = {
        publishAccount: undefined,
        principalIdListData: undefined,
      };
      this.mediaDetail = {};
      this.showModal = false;
    },
    handleTypeChange() {
      this.searchParams.meidaId = undefined;
      this.searchParams.principalIdListData = undefined;
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    async getRewriteTypeList() {
      this.handleRequest('getToolTypeList', (data) => {
        this.rewriteTypeList = data;
        if (data.length && this.$refs.ContentComp) this.$refs.ContentComp.rewriteType = data[0].id;
      });
    },
    onTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? [
            {
              fieldName: sorter.columnKey,
              orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            },
          ]
        : [];
      this.sortList = sort;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      try {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.tips-txt {
  color: #808080;
}

.themeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .themeItem {
    margin: 2px 2px 0 0;
    padding: 0 15px;
    border-radius: 5px;
    color: #fff;
  }
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .line-flex {
      display: flex;
      align-items: center;
    }

    .right-txt {
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .blue-text {
      color: #1890ff;
      cursor: pointer;
    }
  }

  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
</style>
