import moment from 'moment';

export const autoColumns = [
  {
    align: 'center',
    title: '选题',
    scopedSlots: { customRender: 'writtingTheme' },
  },
  {
    align: 'center',
    slots: { title: 'picSwitchHeader' },
    scopedSlots: { customRender: 'picSwitch' },
  },
  {
    align: 'center',
    title: '文案生产',
    scopedSlots: { customRender: 'productSwitch' },
  },
  {
    align: 'center',
    title: '操作人',
    dataIndex: 'actor',
  },
  {
    align: 'center',
    title: '更新时间',
    dataIndex: 'mtime',
    key: 'mtime',
    sorter: (a, b) => {
      a.timeValue = moment(a.mtime).valueOf();
      b.timeValue = moment(b.mtime).valueOf();
      return a.timeValue - b.timeValue;
    },
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

export const brandColumns = [
  {
    align: 'center',
    title: '媒体号',
    width: 380,
    scopedSlots: { customRender: 'mediaAccount' },
  },
  {
    align: 'center',
    title: '生产材料来自',
    dataIndex: 'sourcePrincipalName',
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

export const accountColumns = [
  {
    align: 'center',
    title: '选题',
    scopedSlots: { customRender: 'writtingTheme' },
  },
  {
    title: '运营爆文',
    align: 'center',
    dataIndex: 'operationCount',
    key: 'operationCount',
    sorter: (a, b) => a.operationCount - b.operationCount,
    customRender: (text, record, index) => {
      return record.operationCount || 0;
    },
  },
  {
    title: '文案模板',
    align: 'center',
    dataIndex: 'templateCount',
    key: 'templateCount',
    sorter: (a, b) => a.templateCount - b.templateCount,
    customRender: (text, record, index) => {
      return record.templateCount || 0;
    },
  },
  {
    title: '平台爆文',
    align: 'center',
    dataIndex: 'platformCount',
    key: 'platformCount',
    sorter: (a, b) => a.platformCount - b.platformCount,
    customRender: (text, record, index) => {
      return record.platformCount || 0;
    },
  },
];
